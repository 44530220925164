<script>
export default {
	lang: 'login',
	computed: {
		hasFacebook() {
			return !!this.$srv('SocialLogin.facebookAppId')
		},
		hasGoogle() {
			return !!this.$srv('SocialLogin.googleClientId')
		},
		hasAnyThirdPartyLogin() {
			return this.hasFacebook || this.hasGoogle
		},
		thirdPartyLoginsTitle() {
			let thirdPartyLogins = []
			if (this.hasGoogle) thirdPartyLogins.push('Google')
			// if (this.hasFacebook) thirdPartyLogins.push('Facebook')
			thirdPartyLogins = thirdPartyLogins.join(' / ')
			return this.$lang('Con tu cuenta de {thirdPartyLogins}', {
				thirdPartyLogins,
			})
		},
	},
}
</script>

<template>
	<div v-if="hasAnyThirdPartyLogin" class="pt-4">
		<div class="font-weight-bold pb-4">{{ thirdPartyLoginsTitle }}</div>
		<div class="d-flex flex-wrap">
			<div class="pr-4 pb-4" v-if="hasGoogle">
				<GoogleAuthButton />
			</div>
			<!-- <div class="pr-4 pb-4" v-if="hasFacebook">
				<FacebookAuthButton />
			</div> -->
		</div>
	</div>
</template>

<style></style>
