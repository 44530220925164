<script>
import { sync, get } from 'vuex-pathify'
export default {
	metaInfo() {
		if (!this.clientId) return
		let googleScript = {
			src: 'https://accounts.google.com/gsi/client',
			async: true,
			defer: true,
		}
		return { script: [googleScript] }
	},
	data() {
		return {
			clientId: this.$srv('SocialLogin.googleClientId'),
		}
	},
	computed: {
		user: get('shop/user'),
		loginLoading: sync('shop/loginLoading'),
		googleLoginLoading: sync('shop/googleLoginLoading'),
	},
	methods: {
		handleCredentialResponse(response) {
			this.$shopApi.post({
				url: '/user/auth/google',
				data: { googleAccessToken: response.credential },
				loading: (v) => {
					this.loginLoading = v
					this.googleLoginLoading = v
				},
				onSuccess: ({ data }) => {
					let { loginMethod, user, isSignup } = data
					this.$eventer().trigger(isSignup ? 'user:signup' : 'user:signin', { loginMethod, user })
					this.$store.set('shop/user', user)
					this.$store.set('shop/loginDrawer', false)
					if (data.contextId != this.$srv('contextId')) {
						window.location.reload()
						return new Promise((resolve) => setTimeout(resolve, 1000 * 60))
					}
				},
			})
		},
		initGoogleLogin() {
			if (!this.clientId) return
			this.$nextTick(async () => {
				await new Promise((resolve) => {
					if (window.google) return resolve()
					let itv = setInterval(() => {
						if (window.google) {
							clearInterval(itv)
							resolve()
						}
					}, 300)
				})

				window.google.accounts.id.initialize({
					client_id: this.clientId,
					callback: (...args) => this.handleCredentialResponse(...args),
				})

				window.google.accounts.id.renderButton(this.$refs.googleButtonContainer, {
					theme: 'filled_blue',
					text: 'signin_with',
					type: 'standard',
					shape: 'pill',
					size: 'large',
					logo_alignment: 'left',
				})

				if (!this.user) {
					window.google.accounts.id.prompt()
				}
			})
		},
	},
	mounted() {
		this.initGoogleLogin()
	},
}
</script>

<template>
	<div v-if="clientId" :class="{ disabled: googleLoginLoading }">
		<div ref="googleButtonContainer"></div>
	</div>
</template>

<style scoped>
.disabled {
	opacity: 0.5;
}
.disabled,
.disabled ::v-deep * {
	pointer-events: none;
}
</style>

