<script>
export default {
	lang: 'login',
	data() {
		return {
			email: null,
			successMsg: false,
			loading: false,
			validation: {},
		}
	},
	methods: {
		submit() {
			this.successMsg = false
			this.$shopApi.post({
				url: '/user/reset-pass',
				data: {
					email: this.email,
				},
				onValidation: ({ validation }) => (this.validation = validation),
				loading: (v) => (this.loading = v),
				onSuccess: () => {
					this.successMsg = true
				},
			})
		},
		openLogin() {
			this.$emit('open-form', 'login')
		},
	},
	mounted() {
		this.$eventer().trigger('forgot-password-form:view')
	},
}
</script>

<template>
	<div class="py-4">
		<v-form @submit.prevent="submit">
			<div class="font-weight-bold pb-4">{{ 'Ingresa el E-mail con el cual te registraste' | lang }}</div>
			<Validator :validation="validation">
				<v-row>
					<v-col cols="12">
						<TextField v-model="email" label="E-mail" />
					</v-col>
					<v-col cols="12" class="d-flex align-center">
						<Button class="success" :loading="loading" type="submit"> {{ 'Recuperar' | lang }} </Button>
						<v-spacer />
						<Button text small @click.stop="openLogin" color="link">{{ 'Atrás' | lang }}</Button>
					</v-col>
					<v-col cols="12" v-if="successMsg">
						<v-alert text dense type="success">
							{{
								'Te enviamos un email con la información para que puedas volver a acceder a tu cuenta.'
									| lang
							}}
							<br />
							<small>{{
								'Si no lo encuentras en Bandeja de Entrada, revisa el Correo no deseado.' | lang
							}}</small>
							<div class="mt-2 text-right">
								<Button class="mt-2" color="link" @click="openLogin" text>
									{{ 'Acceder a mi cuenta' | lang }}
								</Button>
							</div>
						</v-alert>
					</v-col>
				</v-row>
			</Validator>
		</v-form>
	</div>
</template>

