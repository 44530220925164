<script>
export default {
	props: {
		value: {
			type: String,
			default: 'login',
		},
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	watch: {
		active(value) {
			this.$emit('updateValue', value)
		},
		value(value) {
			this.active = value
		},
	},
	computed: {
		formComponent() {
			switch (this.active) {
				case 'forgot':
					return 'ForgotForm'
				case 'signup':
					return 'SignupForm'
				case 'login':
				default:
					return 'LoginForm'
			}
		},
	},
	data() {
		return {
			active: this.value,
		}
	},
}
</script>

<template>
	<component :is="formComponent" @open-form="active = $event" />
</template>

<style></style>
